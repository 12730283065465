
export default {
    title: 'Reports',
    pointsAccount: 'Point accounts report',
    paymentsExport: 'Payments report',
    profomaInvoice: 'Platform profoma invoice',
    bookingReport: 'Booking report',
    toDate: 'To date',
    date: 'Date',
    create: 'Create',
    createdDateRange: 'Created at date range',
    created: 'The report has been created',
    downloadReport: 'Download report',
    invalidDateRangeMessage: 'Please select a valid date range (start date and end date)',
    createdAndSent: 'The report has been created and will be sent to your email',
    kpi:{
        title: 'KPI Export',
        period: 'Period',
        periodType: 'Period type',
        sendToEmail: 'Send to email',
        created: 'The report has been created',
        kpiReport: 'KPI Report'
    },
}